import styled from 'styled-components';

export const ModalBody = styled.div``;

export const HelloHeading = styled.h3`
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 4.5em;
  border-bottom: none;
  margin-bottom: 0.5em;
`;
